import { Twitter, Instagram, Youtube, Disc, Send, ArrowRight, MessageCircle, Globe, Star, Target, Users, Banknote } from 'lucide-react';
import evanTeam from './../assets/evanteam.png';
import summitTeam from './../assets/summitteam.png';
import elaineTeam from './../assets/elaineteam.png';
import mayaTeam from './../assets/mayateam.png';
import sushilTeam from './../assets/sushilteam.png';
import lydiaTeam from './../assets/lydiateam.png';
import bgVideo from './../assets/video/Hero2.mp4';
import bgVideoMobile from './../assets/video/HeroSmall2.mp4';
import bgStrength from './../assets/StrengthBg.png';
import bgStrengtMobile from './../assets/PhilosophyMobile.png';
import bgTeam from './../assets/TeamBg.png';
import react, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import PortfolioCard from './../components/portfolioCard';
import StrengthCard from './../components/strengthCard';
import AnimatedText from './../components/AnimatedText';
import Footer from './../components/footer';
import { Link, useLocation } from 'react-router-dom'
import symbolLogo from './../assets/logo.png';
import {globalorderlist, investors, kolslist, portfolioItems, stories, testimonials } from './../components/data';
import { TracingBeam } from "./../components/ui/tracingbeam.tsx";
import KolsCard from './../components/kolCard.js';


const PrevArrow = ({ className, style, onClick }) => (
  <button
    className={`${className} z-10 rounded-full p-2 shadow-md`}
    style={{ ...style, display: 'block', left: '10px' }}
    onClick={onClick}
  >
  </button>
);

const NextArrow = ({ className, style, onClick }) => (
  <button
    className={`${className} z-50 rounded-full p-2 shadow-md`}
    style={{ ...style, display: 'block', right: '10px' }}
    onClick={onClick}
  >
  </button>
);




function Home() {
  const investorTextsHero = [
    "+900 KOLs",
    "$25M+ INVESTED",
    "+50 FUNDED",
    "+900 KOLs",
    "$25M+ INVESTED",
    "+50 FUNDED",
    "+900 KOLs",
    "$25M+ INVESTED",
    "+50 FUNDED",
    "+900 KOLs",
    "$25M+ INVESTED",
    "+50 FUNDED",
    "+900 KOLs",
    "$25M+ INVESTED",
    "+50 FUNDED",
  ];
  const investorTagsHero = [
    "$250M AUM",
  ];
  const statshero = [
    "Strategic Partnerships with Top Crypto Exchanges",
    "Comprehensive Advisory Services",
    "Elite Network of Investors and CEOs",
    "Pioneering Web3 Innovation",
    "Global Market Penetration",
    "Industry-Leading Marketing Strategies",
    "Extensive Industry Insights",
    "Transparency and Accountability Focused",
    "Community-Driven Approach",
    "Disruptive Project Highlighting",
  ];
  const statshero2 = [
    "Influencer-Driven Promotion",
    "Active Engagement with Portfolio Companies",
    "Tailored Support for Startups",
    "Expertise in Cryptocurrency Landscape",
    "Leading KOLs from Major Crypto Regions",
    "Unmatched Networking Opportunities",
    "Revolutionizing Early-Stage Crypto Startups",
    "Empowering Key Opinion Leaders",
    "Driving Meaningful Change in Web3",
    "Building a Safer, Inclusive Web3 Environment",
    "Championing Innovation and Transparency",
    "Catalyst for Growth and Success",
  ];


  const testimonialsHero = [
    {
      quote: "I've had the pleasure of collaborating with KOL Capital...",
      author: "IBCIG",
      link: "https://twitter.com/ibcig"
    },
    {
      quote: "Kol Kapital offers us many great opportunities...",
      author: "PATRON",
      link: "https://twitter.com/genelpatron01"
    },
    {
      quote: "KOL Capital is the place where I got the opportunity to invest in some good deals...",
      author: "VLAD STOICA",
      link: "https://www.instagram.com/vladstoica/"
    },
    {
      quote: "It is always a pleasure to work with KOL Capital team...",
      author: "CRYPTODIFFFER",
      link: "https://twitter.com/CryptoDiffer"
    },
    {
      quote: "My experience with KOL Capital has been great so far...",
      author: "GUIDO CARLO",
      link: "https://twitter.com/RomeoTrades"
    },
    {
      quote: "Working with KOL Capital has been fantastic...",
      author: "DCI",
      link: "https://twitter.com/dci_crypto"
    },
    {
      quote: "KOL Capital has done well so far, considering it's a new VC...",
      author: "JOSHWORLD",
      link: "https://www.youtube.com/CryptoWorldJosh"
    },
    {
      quote: "Kol Capital consists of a highly qualified and successful team...",
      author: "KRYPTO KAHIN",
      link: "https://x.com/kahincryptocu"
    },

  ];

  const teamMembers = [
    {
      name: "Evan Luthra",
      role: "Co-Founder & Partner",
      image: evanTeam,
      description: [
        "Evan Luthra is a visionary entrepreneur and seasoned investor with a deep passion for the Web3 space. Known for his early-stage investments and strategic insights, Evan has been instrumental in shaping the future of numerous crypto startups. With a vast network of industry contacts and a keen eye for innovation, he brings a wealth of experience and expertise to KOL Capital.",
        "Evan's journey in the tech and crypto industries has been marked by a relentless drive to identify and nurture disruptive technologies. His strategic guidance and extensive knowledge of the market dynamics make him a key asset to our team and portfolio companies."
      ],
      socials: [
        { icon: "Globe", link: "https://www.evanluthra.com" },
        { icon: "Twitter", link: "https://twitter.com/evanluthra" },
        { icon: "Instagram", link: "https://instagram.com/evanluthra" },
        { icon: "Send", link: "https://t.me/EvanLuthraVIP" }
      ]
    },
    {
      name: "Sumit Kapoor",
      role: "Co-Founder & Partner",
      image: summitTeam,
      description: [
        "Sumit Wise Advice is a seasoned investor and entrepreneur with a profound understanding of the crypto ecosystem. As a co-founder of KOL Capital, Sumit brings his extensive experience and strategic foresight to the forefront of our operations. His ability to identify high-potential projects and provide them with the necessary support has been pivotal in driving the success of our investments.",
        "With a background in finance and a strong network of industry connections, Sumit plays a crucial role in guiding our portfolio companies through the complexities of the crypto market. His dedication to fostering innovation and transparency aligns perfectly with KOL Capital's mission to revolutionize the Web3 landscape."
      ],
      socials: [
        { icon: "Globe", link: "https://wiseadvice.in/" },
        { icon: "Twitter", link: "https://x.com/wiseadvicesumit" },
        { icon: "Instagram", link: "https://www.instagram.com/wiseadvicecrypto" },
        { icon: "Send", link: "https://t.me/wiseadvicebysumit" }
      ]
    },
    {
      name: "Lydia Latit",
      role: "Principal",
      image: lydiaTeam,
      description: [
        "Lydia is a prominent figure in the cryptocurrency industry, known for her expertise in high-level networking, strategic market analysis, and investment opportunities. As a principal at KOL Capital, she plays a key role in connecting top blockchain projects with influential Key Opinion Leaders (KOLs), driving visibility and accelerating adoption.",
        "With extensive experience as a listing director for crypto exchanges and a trusted crypto advisor, Lydia has been instrumental in securing strategic investments and guiding projects through critical growth phases."
      ],
      socials: []  // Add social links as needed
    },
    {
      name: "Elaine Yang",
      role: "Principal",
      image: elaineTeam,
      description: [
        "Elaine is an experienced investor who has founded an Bitcoin ecosystem fund -Satoshi Lab and an AI accelerator-AI101Labs, focusing on driving innovation and development in Web3 and AI technologies. With extensive experience in various industries, Elaine has developed deep expertise in the Web3 and AI sectors.",
        "As the Principal of KOL Capital, Elaine's role extends beyond capital management; she also provides unique insights into market expansion and growth strategies, helping early-stage projects achieve breakthrough success. With significant influence across the Asia-Pacific region, she is dedicated to building a global ecosystem that promotes the growth and scaling of innovative projects."
      ],
      socials: []  // Add social links as needed
    },
    {
      name: "Sushil Uniyal",
      role: "Operations Lead",
      image: sushilTeam,
      description: [
        "Sushil Uniyal is a Web3 investor with four years of experience in the blockchain industry. As a Manager at KOL Capital, he plays a pivotal role in managing investor relations, optimizing internal processes, and driving high-impact partnerships between top KOLs, influencers, and blockchain projects.",
        "With a background in B.Tech Computer Science Engineering specializing in Blockchain Technologies, Sushil built a strong technical foundation before fully immersing himself in the crypto industry."
      ],
      socials: []  // Add social links as needed
    },
    {
      name: "Maya on chain",
      role: "Principal",
      image: mayaTeam,
      description: [
        "Multilingual professional with double master's degrees and a background at Mercedes-Benz & Bosch, now fully immersed in crypto. With 4+ years of experience in KOL sourcing, matchmaking, relations, negotiations, and deal structuring, Maya specializes in connecting KOLs and niche creators with web3 projects, fostering strategic partnerships, and driving community growth."
      ],
      socials: []  // Add social links as needed
    },
  ];

  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const settingsTeam = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    autoplay: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };



  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#team') {
      const anchor = document.querySelector('#team');
      anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }, [location]);


  const settings2 = {
    ...settings,
    rows: 2,
    slidesPerRow: 3
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTeam = () => {
    const anchor = document.querySelector('#team');
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };
  const scrollToTestimonials = () => {
    const anchor = document.querySelector('#testimonials');
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };
  const scrollToContact = () => {
    const anchor = document.querySelector('#contactus');
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          const scale = Math.min(
            entry.contentRect.width / 1750,
            entry.contentRect.height / 800
          );
          containerRef.current.style.transform = `scale(${scale})`;
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const customSliderStyle = {
    height: '400px',  // Custom height for slider 2
  };
  
  const footerRef = useRef<HTMLDivElement>(null);

  const [glowWelcome, setGlowWelcome] = useState(false);
  const [glowKol, setGlowKol] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setGlowWelcome(true);
      setTimeout(() => {
        setGlowWelcome(false);
        setGlowKol(true);
        setTimeout(() => {
          setGlowKol(false);
        }, 1000);
      }, 1000);
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className="backgroundColors min-h-[740%]">
    {/*<div className='Overlay-bg right-[0%] opacity-80'></div>*/}
    <div className="  text-white min-h-screen md:px-8 mb-10 md:mb-0 scroll-smooth	" >
      <header className="flex justify-center items-center mb-2 w-full">
      <nav className="w-full z-50 m-2">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
        <img src={symbolLogo} class="h-12" alt="KOL Capital Logo" />
          <span className="self-center text-sm font-semibold whitespace-nowrap dark:text-white">KOL Capital</span>
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
            <button onClick={scrollToContact} type="button" className="hidden md:block text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-8 h-8 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none "
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block bg-black' : 'hidden '}`} id="navbar-sticky">
          <ul className="flex flex-col text-xs p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
              <Link to="#" className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 md:dark:text-blue-500" aria-current="page">Home</Link>
            </li>
            <li>
              <Link to="/portfolio" className="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Portfolio</Link>
            </li>
            <li>
              <Link to="/kols" className="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Networks</Link>
            </li>
            <li>
              <div onClick={scrollToTeam} className="block cursor-pointer py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Team</div>
            </li>
            <li>
                <Link to="/stories" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Case Studies</Link>
            </li>
            <li>
                <Link to="/testimonials" class="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Testimonials</Link>
            </li>
            <button onClick={scrollToContact} type="button" className="block md:hidden text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          </ul>
        </div>
      </div>
    </nav>
      </header>

      <div className="absolute top-[50px] left-0 w-full h-full origin-center">
      <video autoPlay loop muted className="video-bg">
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video autoPlay loop muted playsInline className="video-bg video-mobile">
        <source src={bgVideoMobile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <main className="Hero-bg relative w-full h-full flex flex-col items-center">
        {/*
      <h2 
        className={`hero-welcome-to text-xs mb-12 opacity-60 absolute top-[14.5vh] left-[40%] md:left-[47.5%] md:top-[46.5vh] transition-all duration-300 ${
          glowWelcome ? 'text-white opacity-100 drop-shadow-[0_0_10px_rgba(255,255,255,1)]' : ''
        }`}
      >
        WELCOME TO
      </h2>
      <h2 
        className={`hero-kol-capital text-md mb-12 absolute font-bold top-[59.4vh] left-[38%] md:left-[46.9%] md:top-[52vh] transition-all duration-300 ${
          glowKol ? 'text-white opacity-100 drop-shadow-[0_0_10px_rgba(255,255,255,1)]' : ''
        }`}
      >
        KOL CAPITAL
      </h2>
      */}
      <div className="flex justify-between w-full mb-16 opacity-0 md:opacity-100">
        <div className="w-1/3">
          <p className="text-xs mb-4 absolute top-[27.5vh] left-[17.5%]"><AnimatedText texts={investorTagsHero} interval={3000}/></p>
          {/*
          <blockquote className="italic text-xs text-gray-400 absolute top-[19vh] left-[10%] w-[15%] cursor-pointer hero-quote-block-left">
            <Link to="/testimonials">
              <AnimatedText texts={testimonialsHero.map(t => t.quote)} interval={7000} />
            </Link>
          </blockquote>
          
          <p className="text-xs mt-2 absolute top-[26vh] left-[10%]  hero-author-left-text">
            <AnimatedText texts={statshero} interval={7000} />
          </p>
          */}
        </div>

        <div className="w-1/3 text-right opacity-0 md:opacity-100">
          <p className="text-xs mb-4 absolute top-[27.5vh] right-[18%]"><AnimatedText texts={investorTextsHero} interval={5000}/></p>
          {/*
          <blockquote className="italic text-xs text-gray-400 absolute top-[19vh] right-[10%] w-[15%] cursor-pointer hero-quote-block-right" >
          <Link to="/testimonials">
            <AnimatedText texts={testimonialsHero.map(t => t.quote)} interval={11000} />
            </Link>
          </blockquote>
           
          <p className="text-xs mt-2 absolute top-[26vh] right-[10%] hero-author-right-text">
          <AnimatedText texts={statshero2} interval={10000} />
          </p>
          */}
        </div>
      </div>

      <div className="flex justify-between mb-16 absolute top-[43vh] w-4/5 right-[10%] opacity-0 md:opacity-100 hero-socials-container">
        <div className="w-1/3">
          <p className="text-xs mb-10 opacity-30">SOCIALS</p>
          <div className="flex space-x-4 opacity-50">
          <Link to="https://www.x.com/kolcapital">
            <Twitter className="w-4 h-4 hover:text-blue-500 cursor-pointer" />
            </Link><Link to="https://www.instagram.com/kolcapital">
            <Instagram className="w-4 h-4 hover:text-pink-500 cursor-pointer" />
            </Link>{/*<Link to="https://www.youtube.com/">
            <Youtube className="w-4 h-4 hover:text-red-500 cursor-pointer" />
            </Link><Link to="https://www.discord.gg/">
            <Disc className="w-4 h-4 hover:text-blue-500 cursor-pointer" />
            </Link><Link to="https://kolcapital.notion.site/KOL-Capital-75eaab00a70f4d0facfc8bcc56e8928c">
            <Send className="w-4 h-4 hover:text-blue-500 cursor-pointer" />
            </Link>*/}
          </div>
        </div>

        <div className="w-1/3">
          {/* Placeholder for center content */}
        </div>

        <div className="w-1/3 text-right opacity-0 md:opacity-100">
          <p className="text-xs mb-10 opacity-30">MOST PERFORMED PROJECTS</p>
          <div className="flex justify-end gap-4">
            <Link to="https://www.playbux.co/" target="_blank" rel="noopener noreferrer" >
            <img src="https://kolcapital.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F0f218168-f18a-4cf1-8c6a-c9af12be162e%2Fbbc22394-e107-4c15-b965-123805e14ff5%2Fa3RI7R9c_400x400.jpg?table=block&id=e4066849-fb39-4eb8-9696-516be1a6d6c5&spaceId=0f218168-f18a-4cf1-8c6a-c9af12be162e&width=60&userId=&cache=v2" className="w-5 h-5 rounded-full" />
            </Link><Link to="https://ivendpay.com/" target="_blank" rel="noopener noreferrer" >
            <img src="https://kolcapital.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F0f218168-f18a-4cf1-8c6a-c9af12be162e%2F440423dc-151d-42db-a9ca-f4bf6cee7815%2F11fv0cal_400x400.jpg?table=block&id=e1f26ba3-770a-4d1c-aa90-15885aa52afc&spaceId=0f218168-f18a-4cf1-8c6a-c9af12be162e&width=60&userId=&cache=v2" className="w-5 h-5 rounded-full" />
            </Link><Link to="https://forwardprotocol.io/" target="_blank" rel="noopener noreferrer" >
            <img src="https://kolcapital.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F0f218168-f18a-4cf1-8c6a-c9af12be162e%2F93bb4448-4507-4315-ba4f-5d1d4f51b02b%2FvqFZMtFJ_400x400.jpg?table=block&id=d1cea835-ec8b-43bc-9be5-bcabab1e9a18&spaceId=0f218168-f18a-4cf1-8c6a-c9af12be162e&width=60&userId=&cache=v2" className="w-5 h-5 rounded-full" />
            </Link><Link to="https://minterest.com/" target="_blank" rel="noopener noreferrer" >
            <img src="https://kolcapital.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F0f218168-f18a-4cf1-8c6a-c9af12be162e%2Fcfed1b9d-9d07-4006-9568-5ceac3e87e61%2Fminterest.jpg?table=block&id=d9cf547b-e5a7-44ce-82fa-acf914d401de&spaceId=0f218168-f18a-4cf1-8c6a-c9af12be162e&width=60&userId=&cache=v2" className="w-5 h-5 rounded-full" />
            </Link>
            <Link to="/portfolio">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='opacity-30 hover:opacity-100' viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ellipsis"><circle cx="12" cy="12" r="1"/><circle cx="19" cy="12" r="1"/><circle cx="5" cy="12" r="1"/></svg>            </Link>
          </div>
        </div>
      </div>
    </main>

  <div className="max-w-[80%] md:max-w-2xl gap-8 mx-auto -mt-[36vh] justify-between items-center z-50 fadeKol mb-16 space-y-5">
    <h2 className="text-2xl md:text-4xl md:mb-4 text-center ">KOL Capital accelerates the growth of promising blockchain ventures</h2>
    <p className="mb-8 text-gray-500 text-sm text-center">
    KOL Capital is a premier Crypto VC Fund that brings together top influencers and experts in the Crypto/Web3 and blockchain space to invest in high-potential Web3 projects. We provide financial backing, marketing, strategic networking, and communication to help these projects thrive in the exciting and ever-changing world of blockchain and crypto.

    </p>
    <button  onClick={scrollToContact} className="hidden bg-blue-600 text-white py-3 px-6 rounded-full hover:cursor-pointer flex justify-center hover:bg-blue-700 transition duration-300 z-50">
    Contact Us
    </button>
  </div>
</div>

    </div>
    

    <TracingBeam className="flex flex-col justify-center scroll-smooth">
    <section className="relative p-8 flex justify-center items-center max-w-6xl mx-auto mt-40 fadeKol">
      <div className=" absolute w-2/3 overflow-hidden text-white z-10">
        
        <div className="flex flex-col md:flex-col lg:flex-col justify-center items-center">
          {/* Left column */}
          <div className="mb-5 md:mb-4 md:max-w-2xl">
            <h2 className="text-xl md:text-4xl lg:text-2xl mt-20 sm:mt-20 md:mt-4 leading-tight text-center md:text-center">
            Investing and Empowering Early-Stage Crypto Startups Alongside a Top-Tier KOLs Network.
            </h2>
          </div>
          
          {/* Right column */}
          <div className="mb-8 md:mb-10 text-center mt-4 mb-14 bg-gradient-to-tr from-black/20 to-transparent p-4 rounded-lg">
              <h3 className="text-xl md:text-6xl lg:text-2xl mb-2">250M+</h3>
              <p className="text-gray-400 uppercase text-xs md:text-sm">Audience Reached</p>
            </div>
          <div className=" grid grid-cols-2 md:grid-cols-2 gap-8 ">
            <div className="mb-2 md:mb-4 text-center bg-gradient-to-tr from-black/20 to-transparent p-4 rounded-lg">
              <h3 className="text-xl md:text-6xl lg:text-2xl mb-2">$25M+</h3>
              <p className="text-gray-400 uppercase text-xs md:text-sm">Invested</p>
            </div>
            
            <div className="mb-2 md:mb-4 text-center bg-gradient-to-tr from-black/20 to-transparent p-4 rounded-lg">
              <h3 className="text-xl md:text-6xl lg:text-2xl mb-2">$250M+</h3>
              <p className="text-gray-400 uppercase text-xs md:text-sm">AUM</p>
            </div>
            <div className="mb-2 md:mb-4 text-center bg-gradient-to-tr from-black/20 to-transparent p-4 rounded-lg">
              <h3 className="text-xl md:text-6xl lg:text-2xl mb-2">+50</h3>
              <p className="text-gray-400 uppercase text-xs md:text-sm">Project funded</p>
            </div>
            <div className="mb-2 md:mb-4 text-center bg-gradient-to-tr from-black/20 to-transparent p-4 rounded-lg">
              <h3 className="text-xl md:text-6xl lg:text-2xl mb-2">+900</h3>
              <p className="text-gray-400 uppercase text-xs md:text-sm">KOLs</p>
            </div>


          </div>
        </div>
      </div>
      <div className="relative h-full">
          <picture>
            <source media="(max-width: 767px)" srcSet={bgStrengtMobile} />
            <source media="(min-width: 768px)" srcSet={bgStrength} />
            <img 
              src={bgStrength}
              alt="Stylized background" 
              className="w-full h-full object-cover"
            />
          </picture>
        </div>
    </section>

    <section className=" text-white p-8 fadeKol">
      <div className="max-w-[90%] md:max-w-7xl mx-auto">
        <div className='max-w-[80%] md:max-w-2xl grid grid-cols-1  gap-8 mb-10 mx-auto'>
        <h2 className="text-4xl mb-4 text-center ">Portfolio</h2>
        <div className="relative z-10">
        <p className="font-bold text-md mb-2 text-center ">Showcasing Our Impactful Investments</p>
        <p className="mb-8 text-sm  text-gray-300 text-center ">
        In 2024 alone, KOL Capital has invested over $5,000,000 in leading projects, driving innovation and growth in the Web3 space.
        </p>
        <Link to="/portfolio" className=' flex justify-center ' >
            <button className="bg-transparent border font-bold text-xs border-white/30 text-white py-2 px-6 rounded-full hover:bg-white hover:text-gray-900 transition duration-300">
              VIEW ALL
            </button>
            </Link>

        </div>
        </div>
        <div className="w-full mx-auto">
        <Slider {...settings}>
        {portfolioItems.map((item, index) => (
            <div key={index} className="px-2">
            <PortfolioCard key={index} {...item} />
            </div>
          ))}
        </Slider>
      </div>

      </div>
    </section>

    <section className=" text-white mx-auto justify-between gap-8 max-w-[90%] md:max-w-7xl flex flex-col fadeKol">
        <div className='max-w-[80%] md:max-w-2xl grid grid-cols-1  gap-8 mb-10 mx-auto'>
        <h2 className="text-4xl mb-4 text-center ">Why Choose KOL Capital</h2>
        <div className="relative z-10">
        <p className="font-bold text-md mb-2 text-center ">Empowering the Future of Web3</p>
        <p className="mb-8 text-sm  text-gray-300 text-center ">
        At KOL Capital, we go beyond traditional venture capital by offering comprehensive support that drives success. Discover why leading Web3 projects choose us.
        </p>
        <div  onClick={scrollToContact} className=' flex justify-center' >
            <button className="bg-transparent border  font-bold text-xs border-white/30 text-white py-2 px-6 rounded-full hover:bg-white hover:text-gray-900 transition duration-300">
              Contact Us
            </button>
            </div>

        </div>
        </div>

        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <StrengthCard
            icon={<Banknote className="w-8 h-8" />}
            title="Financial Backing"
            description="We provide financial support to promising Web3 projects, empowering them to grow and reach their full potential. Our strategic partnerships and industry expertise ensure your project's success from inception to market dominance."
          />
          
          <StrengthCard
            icon={<Users className="w-8 h-8" />}
            title="Networking and strategic communication"
            description="We provide not only capital but also strategic networking and partnerships. Our extensive connections include leading launchpads, centralized exchanges (CEXs), market makers, and venture capital firms. We support your project both pre- and post-launch, ensuring it gains the traction needed for long-term success. With our financial backing and industry connections, we empower your startup to innovate and scale effectively."
          />
          
          <StrengthCard
            icon={<Target className="w-8 h-8" />}
            title="KOL Marketing"
            description=" We leverage our extensive network of over 900 Key Opinion Leaders (KOLs), including top-tier influencers, to help you develop and implement effective marketing strategies. Our approach ensures increased visibility for your project and attracts more investors, driving your success in the competitive market."
          />
        </div>
    </section>

    <section className=" text-white p-8 fadeKol">
      <div className="max-w-[90%] md:max-w-7xl mx-auto">
        <div className="max-w-[80%] md:max-w-2xl grid grid-cols-1  gap-8 mb-12 mx-auto">
          <div>
            <h2 className="text-4xl  mb-4 text-center">Our Leading KOLs</h2>
          </div>
          <div>
          <p className="font-bold text-md mb-2 text-center">Meet Our Influential Partners</p>
            <p className="text-gray-300 text-sm mb-6 text-center">
            KOL Capital is powered by a diverse and extensive network of top influencers, each bringing their unique expertise and community reach to support our mission. Here are some of our most influential partners:
            </p>
            <Link to="/kols" className=' flex justify-center' >
            <button className="bg-transparent border  font-bold text-xs border-white/30 text-white py-2 px-6 rounded-full hover:bg-white hover:text-gray-900 transition duration-300">
              VIEW ALL
            </button>
            </Link>
          </div>
        </div>
        
        <div className="w-full mx-auto">
        <Slider {...settings}>
          {globalorderlist.slice(0, 10).map((kol, index) => (
            <div key={index} className="px-2">
              <KolsCard {...kol} />
            </div>
          ))}
        </Slider>
      </div>
      </div>
    </section>



    <section className=" text-white p-8 fadeKol" id="team">
      <div className="max-w-[90%] md:max-w-7xl mx-auto">
        <div className="max-w-[80%] md:max-w-2xl grid grid-cols-1 gap-8 mb-12 mx-auto">
          <div>
            <h2 className="text-4xl  mb-4 text-center">Meet the Team</h2>
          </div>
          <div>
              <p className="font-bold text-md mb-2 text-center">The Visionaries Behind KOL Capital</p>
              <p className="text-gray-300 text-sm mb-6 text-center">
            Our team is led by two exceptional individuals whose expertise and vision drive KOL Capital's success. Together, they bring unparalleled experience and a deep understanding of the crypto landscape, ensuring that our portfolio companies receive the support they need to thrive.
            </p>
          </div>
        </div>

        
    <Slider {...settingsTeam}>
      {teamMembers.map((member, index) => (
        <div key={index} className="px-4">
          <div className="relative flex flex-col gap-4 p-4 md:p-10 group h-[900px] overflow-y-auto">
            <div className='z-50 space-y-4 transform transition-colors'>
              <img src={member.image} alt={member.name} className="w-full" />
              <div className='flex justify-between items-center'>
                <div className="text-md md:text-xl">{member.name}</div>
                <div className='flex gap-4'>
                  {member.socials.map((social, idx) => (
                    <a key={idx} href={social.link} target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
                      {social.icon === "Globe" && <Globe size={14} />}
                      {social.icon === "Twitter" && <Twitter size={14} />}
                      {social.icon === "Instagram" && <Instagram size={14} />}
                      {social.icon === "Send" && <Send size={14} />}
                    </a>
                  ))}
                </div>
              </div>
              <h2 className="text-sm md:text-md">{member.role}</h2>
              <div className="overflow-y-auto">
                {member.description.map((paragraph, idx) => (
                  <p key={idx} className="text-gray-400 text-xs md:text-sm mb-4">{paragraph}</p>
                ))}
              </div>
            </div>
            <div className="absolute z-10 inset-0">
              <img
                src={bgTeam}
                alt="Crypto landscape illustration"
                className="w-full h-full object-fit transition-opacity opacity-0 duration-300 group-hover:opacity-100"
              />
            </div>
          </div>
        </div>
      ))}
    </Slider>

        
      {/*<div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-10">
      <div className="relative flex flex-col gap-4 p-4 md:p-10 group">
      <div className=' z-50 space-y-4 transform transition-colors'>
      <img src={evanTeam}></img>
      <div className='flex justify-between items-center'>
          <div className="text-md md:text-xl">
            Evan Luthra
            </div>
            <div className='flex gap-4'>
            <a href="https://www.evanluthra.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
              <Globe size={14} />
            </a>
            <a href="https://twitter.com/evanluthra" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
              <Twitter size={14} />
            </a>
            <a href="https://instagram.com/evanluthra" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <Instagram size={14} />
            </a>
            <a href="https://t.me/EvanLuthraVIP" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <Send size={14} />
            </a>
            </div>
            </div>
          <h2 className="text-sm md:text-md">
          Co-Founder & Partner
          </h2>
          <p className="text-gray-400 text-xs md:text-sm ">Evan Luthra is a visionary entrepreneur and seasoned investor with a deep passion for the Web3 space. Known for his early-stage investments and strategic insights, Evan has been instrumental in shaping the future of numerous crypto startups. With a vast network of industry contacts and a keen eye for innovation, he brings a wealth of experience and expertise to KOL Capital.</p>
          <p className="text-gray-400 text-xs md:text-sm ">Evan's journey in the tech and crypto industries has been marked by a relentless drive to identify and nurture disruptive technologies. His strategic guidance and extensive knowledge of the market dynamics make him a key asset to our team and portfolio companies.</p>
          </div>
          <div className="absolute z-10 inset-0">
      <img
        src={bgTeam}
        alt="Crypto landscape illustration"
        className="w-full h-full object-fit transition-opacity opacity-0 duration-300 group-hover:opacity-100"
      />
    </div>

        </div>
        <div className="relative flex flex-col gap-4 p-4 md:p-10 group">
          <div className=' z-50 space-y-4 transform transition-colors'>
          <img src={summitTeam}></img>
          <div className='flex justify-between items-center '>
          <div className="text-md md:text-xl">
          Sumit Kapoor
            </div>
            <div className='flex gap-4'>
            <a href="https://wiseadvice.in/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
              <Globe size={14} />
            </a>
            <a href="https://x.com/wiseadvicesumit" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
              <Twitter size={14} />
            </a>
            <a href="https://www.instagram.com/wiseadvicecrypto" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <Instagram size={14} />
            </a>
            <a href="https://t.me/wiseadvicebysumit" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <Send size={14} />
            </a>
            </div>
            </div>     
            <h2 className="text-sm md:text-md">
          Co-Founder & Partner
          </h2>
     
            <p className="text-gray-400 text-xs md:text-sm">Sumit Wise Advice is a seasoned investor and entrepreneur with a profound understanding of the crypto ecosystem. As a co-founder of KOL Capital, Sumit brings his extensive experience and strategic foresight to the forefront of our operations. His ability to identify high-potential projects and provide them with the necessary support has been pivotal in driving the success of our investments.</p>
          <p className="text-gray-400 text-xs md:text-sm">With a background in finance and a strong network of industry connections, Sumit plays a crucial role in guiding our portfolio companies through the complexities of the crypto market. His dedication to fostering innovation and transparency aligns perfectly with KOL Capital's mission to revolutionize the Web3 landscape.</p>
          </div>
          <div className="absolute z-10 inset-0">
      <img
        src={bgTeam}
        alt="Crypto landscape illustration"
        className="w-full h-full object-fit transition-opacity opacity-0 duration-300 group-hover:opacity-100"
      />
    </div>
        </div>
      </div>*/}

      </div>
    </section>
    </TracingBeam>
    </div>
    <div id="contactus">
    <Footer /> 
    </div>
    </div>
  );
};

export default Home;
